$(document).ready(() => {
  $('.close-sm-menu').click(function(){
    $(".navbar-collapse").collapse('hide');
  });

  $('.sub-transparent-filler').hover((event) => {
    const target = $(event.target)
    const menu_item = $(target.attr('menu-item'))
    const collapseTarget = $(target.attr('collapse-target'))
    closeNavbar(menu_item, collapseTarget)
  })

  $('.submenu-collapser').click((event) => {
    event.preventDefault()

    const menu_item = $(event.target)
    const collapseTarget = $(menu_item.attr('collapse-target'))
    const is_closed = collapseTarget.hasClass('closed')

    if (is_closed) {
      openNavbar(menu_item, collapseTarget)
    } else {
      if (is_mobile()) {
        closeNavbar(menu_item, collapseTarget)
      }
    }
  })

  $('.submenu-collapser').hover((event) => {
    if (!is_mobile()) {
      const menu_item = $(event.target)
      const collapseTarget = $(menu_item.attr('collapse-target'))
      openNavbar(menu_item, collapseTarget)
    }
  })

  $(window).scroll((event) => {
    toggleNavbarShadow()
  })

  toggleNavbarShadow()
})

const toggleNavbarShadow = () => {
  const scroll = $(window).scrollTop()
  const navbar = $('.navbar')

  if (scroll >= 100) {
    navbar.addClass('shadowed')
  } else {
    navbar.removeClass('shadowed')
  }
}

const openNavbar = (menu_item, collapseTarget) => {
  menu_item.parent().addClass('opened')
  collapseTarget.removeClass('closed')
}

const closeNavbar = (menu_item, collapseTarget) => {
  menu_item.parent().removeClass('opened')
  collapseTarget.addClass('closed')
}

const is_mobile = () => {
  return document.body.clientWidth < 576
}
