$(document).ready(() => {
  $(window).scroll(() => {
    const scroll = $(window).scrollTop()
    if(scroll > 2 * $(window).height()) {
      $('.back-to-top').addClass('visible')
    } else {
      $('.back-to-top').removeClass('visible')
    }
  })

  $('.back-to-top').click(() => {
    window.scrollTo(0, 0)
  })
})
