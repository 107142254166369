$(document).ready(() => {
  $('[data-toggle="tooltip"]').tooltip()

  $('[copy-on-click]').click((event) => {
    const target = $(event.target)
    const former_title = target.attr('data-original-title')
    copyStringToClipboard($.trim(target.text()))

    target.attr('title', 'Copié !')
          .tooltip('_fixTitle')
          .tooltip('show')

    target.attr('title', former_title)
          .tooltip('_fixTitle')
  })
})

function copyStringToClipboard (str) {
  var $temp = $("<input>");
  $("body").append($temp);
  $temp.val(str).select();
  document.execCommand("copy");
  $temp.remove();
}
