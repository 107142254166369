$(document).ready(() => {
  var current_step = 0
  const logos_list = $('.partners-logos')
  const logos = $('.partner-logo-cel')
  const total_logos = logos.length

  setInterval(() => {
    var logo_index = 0
    const offset = - current_step * $(logos[0]).outerWidth()
    logos_list.css('transform', "translateX("+offset+"px)")

    for(logo of logos) {
      const logo_element = $(logo)

      if (logo_index == (current_step % total_logos)) {
        const image_url = logo_element.children('img').attr('src')
        logos_list.append('<div class="partner-logo-cel col-lg-1 col-md-2 col-4"><img class="partner-logo my-2" src="'+image_url+'"></div>')
      }

      logo_index++
    }

    current_step++
  }, 3000)
})
