$(document).ready(() => {
  const animated_elements = $('.animated-on-sight:not(.visible)')

  $(window).scroll((event) => {
    animate_elements(animated_elements)
  })

  animate_elements(animated_elements)
})

const animate_elements = (animated_elements) => {
  const scroll = $(window).scrollTop()
  const window_height = $(window).height()

  for(animated_element of animated_elements) {
    const element = $(animated_element)
    const element_top_position = element.offset().top
    const element_bottom_position = element_top_position + element.outerHeight()
    const min_point = window_height / 8
    const max_point = window_height * 7 / 8

    if (
      (min_point + scroll <= element_top_position && max_point + scroll >= element_top_position) ||
      (min_point + scroll <= element_bottom_position && max_point + scroll >= element_bottom_position) ||
      (min_point + scroll >= element_top_position && max_point + scroll <= element_bottom_position)
    ) {
      element.addClass('visible')
    }
  }
}
